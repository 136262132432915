import React from 'react';
import './App.css';
import { RequestQuoteForm } from './components/RequestQuoteForm';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Frontier Fence Builders</h1>
      </header>
      <section className="main-image" style={{backgroundImage: `url("/images/bv.jpg")`}}>
      </section>
      <section className="about-us">
        <h2>About Us</h2>
        <p>
          Hi, my name is Neil Martin, owner of Frontier Fence Builders. We are a family-owned and operated fence construction company business based in the Bitterroot Valley, Montana.
          We are a hardworking team that delivers our high quality services promptly, professionally, and at a fair price.
        </p>
      </section>
      <section className="video-section">
      <h2>Our Work in Action</h2>
  <div className="video-wrapper">
    <div className="video-container">
      <iframe
        src="https://www.youtube.com/embed/RiDUT_WsbA8?autoplay=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</section>
      <section className="contact-us">
        <h2>Contact Us</h2>
        <p>Phone: <a href="tel:+14063810197">(406) 381-0197</a></p>
        <button className="contact-button">
          <a href="mailto:frontierfencebuilders@gmail.com" style={{ color: '#ffffff' }}>EMAIL US</a>
        </button>
      </section>
      <section className="example-images">
        <h2>Examples of Our Work</h2>
        <div className="image-grid">
          <img src="/images/f1.jpg" alt="Fence Example 1" />
          <img src="/images/f2.jpg" alt="Fence Example 2" />
          <img src="/images/f3.jpg" alt="Fence Example 3" />
          <img src="/images/f4.jpg" alt="Fence Example 4" />
          <img src="/images/f5.jpg" alt="Fence Example 5" />
          <img src="/images/f6.jpg" alt="Fence Example 6" />
          <img src="/images/f7.jpg" alt="Fence Example 7" />
          <img src="/images/f8.jpg" alt="Fence Example 8" />
          <img src="/images/f9.jpg" alt="Fence Example 9" />
          </div>
          </section>
       <section className="video-section">
      <h2>We are willing and able to go the extra mile</h2>
  <div className="video-wrapper">
    <div className="video-container">
      <iframe
        src="https://youtube.com/embed/Z5Ss9Q_x2qo?autoplay=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</section>
      <section className="request-quote">
        <h2>Request a Quote</h2>
        <RequestQuoteForm/>
      </section>
      <footer style={{paddingBottom: '2rem'}}>
        <p>&copy; {new Date().getFullYear()} Frontier Fence Builders. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
