import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './RequestQuoteForm.css';
export const RequestQuoteForm = () => {
  const form = useRef();
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [showThumbsUp, setShowThumbsUp] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_z5kjkm9', 'template_uyhneqj', form.current, 'TLqEmPNLPK86NhX7n')
    .then(
      (result) => {
        console.log(result.text);
        setShowThankYouMessage(true);
        setShowThumbsUp(true);

        // Send the auto-reply email
        emailjs.send('service_z5kjkm9', 'template_uyhneqj', {
          user_email: form.current.elements.user_email.value,
          user_name: form.current.elements.user_name.value
        }, 'TLqEmPNLPK86NhX7n')
        .then(
          (result) => {
            console.log('Auto-reply sent:', result.text);
          },
          (error) => {
            console.log('Failed to send auto-reply:', error.text);
          });
      },
      (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="request-quote-form-container">
    <div className="requestQuoteForm">
      {showThankYouMessage ? (
        <p>Thank you for the quote request, we will get back to you promptly.</p>
      ) : (
        <form ref={form} onSubmit={sendEmail}>
          <label>Name*</label>
          <input type="text" name="user_name" required />
          <label>Email*</label>
          <input type="email" name="user_email" required />
          <label>Phone</label>
          <input type="tel" name="user_phone" />
          <label>Address or General Location</label>
          <input type="text" name="user_address" />
          <label>Type of Fence</label>
          <input type="text" name="fence_type" />
          <label>Approx Length of Fence</label>
          <input type="text" name="fence_length" />
          <label>Additional Comments</label>
          <textarea name="additional_comments" />
          <label className="pick-a-date">Preferred Start Date</label>
          <input type="date" name="start_date" />
          <input
          type="submit"
          value={showThumbsUp ? "👍 Requested" : "Request a Quote"}
          />
          {showThumbsUp && <span className="thumbsUp">👍</span>}
        </form>
      )}
    </div>
    </div>
  );
};
